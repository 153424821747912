import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import ProjectItem from "./ProjectItem";



const items = [
  {
    imgUrl: './img/lekkarlekkar-card.png',
    title: "Lekkar Lekkar (work in progress)",
    text: "A fast and responsive recipe site. Made using a Node.js/MYSQL backend and client facing React app. My goal was to make a fast recipe site that's not bloated with ads and unncessary content. Personally selected recipes from a passionate home cook.",
    button1Text: 'bon appétit',
    button1Url: 'https://lekkarlekkar.nl',
  },
  {
    imgUrl: './img/boodschappenbeheer-card.png',
    title: "Boodschappenbeheer.nl (work in progress)",
    text: "A simple yet very effective, responsive shopping list manager. Features an administrative layer to add new users, items and categories. All self hosted on a home made Linux server and made using Node.js, MYSQL and React.",
    button1Text: 'login',
    button1Url: 'https://boodschappenbeheer.nl',
  },
  {
    imgUrl: './img/jelly-no-puzzle-card.png',
    title: `Jelly No Puzzle Remake (JavaScript)`,
    text: `What do you do when a cool game you found online is frustrating to play because it doesn't feature a undo-system? You build your own version!`,
    button1Text: 'play',
    button1Url: 'https://jelly.sander-vermeer.nl',
    button2Text: 'github',
    button2Url: 'https://github.com/mohragk/jelly-no-puzzle',
  },
  {
    imgUrl: './img/monosynth-card.png',
    title: "Monosynth-1, an analogue modelled synthesizer VST (C++)",
    text: `This is a software synthesizer, made to be used as a VST plug-in or standalone application. It's realised in C++ using the Juce framework. It features 3 oscillators, 3 different filter models, 3 envelope generators, an LFO and a simple yet powerfull sequencer. `,
    button1Text: 'github',
    button1Url: 'https://github.com/mohragk/VermeerMonosynth-1',
  },
  
  {
    imgUrl: './img/paardenspronggame.png',
    title: `"Paardensprong" word puzzle game (C++)`,
    text: `Inspired by the "2 voor 12" game show, I remade the game in C++ using the SFML framework for rendering and audio. You have to guess the correct 8-letter word. The order can be clockwise or counter clockwise and the letters make a jump using the chess' knight move (2 vertically, 1 horizontally or vice versa). Every word is worth 30 points but counts down every second. After that, each second a point is removed from the overall score.`,
    button1Text: 'github',
    button1Url: 'https://github.com/mohragk/paardensprong-cpp',
  },
  
  {
    imgUrl: './img/reversi-card.png',
    title: "Online Reversi",
    text: "A tech demo of an online multiplayer version of Reversi. Featuring a realtime Socket.IO server engine that only allows valid moves.",
    button1Text: 'play',
    button1Url: 'https://reversi.sander-vermeer.nl',
    button2Text: 'github',
    button2Url: 'https://github.com/mohragk/online-reversi',
  },
  
  {
    imgUrl: './img/vlic-site-card.png',
    title: `VLIC.nl`,
    text: `Single page site, designed by me and made in React for a client.`,
    button1Text: 'visit',
    button1Url: 'https://www.vlic.nl/',
  },
  {
    imgUrl: './img/fourier-card.png',
    title: "A interactive visualization of the Fourier Series in P5.js",
    text: "A rendering of harmonics in audio signals. Switch between square wave, saw wave and triangle wave and add or subtract harmonics. Use the keyboard to play some notes or drag the wheel to show what exactly is going on. Fun for the whole family!",
    button1Url: 'https://editor.p5js.org/mohragk/full/BkMiw4KxV',
    button1Text: 'demo',
    button2Url: 'https://editor.p5js.org/mohragk/sketches/BkMiw4KxV',
    button2Text: 'edit',
  },
  {
    imgUrl: './img/shifter-card.png',
    title: "Home made analog handbrake and sequential shifter using Arduino",
    text: "A simple, robust up-down shifter and handbrake, designed to work with racing games. The control board is programmed in Arduino and extended to work with a (future) handbrake. A GUI was created using Processing for setting different variables and ranges of the handbrake.",
    button1Url: 'https://www.youtube.com/watch?v=uvksFIGrdxg',
    button1Text: 'youtube',
    button2Url: 'https://github.com/mohragk/Shifter_Handbrake_DIY',
    button2Text: 'github'
  },

  {
    imgUrl: './img/escape-card.png',
    title: "Escape reality",
    html:<> <p>ESCAPE x 1 = easy</p> <p>ESCAPE x 2 = medium</p> <p>ESCAPE x 3 = hard</p> </>,
    isHTML: true,
    button1Url: '##',
    button1Text: '',
  }
 
];



class ProjectCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.imgUrl}
        >
          <ProjectItem
            imgUrl={item.imgUrl}
            title={item.title}
            text={item.text}
            button1Text={item.button1Text}
            button1Url={item.button1Url}
            button2Text={item.button2Text}
            button2Url={item.button2Url}
            key={item.title}
            isHTML={item.isHTML}
            html={item.html}
          />
          </CarouselItem>
      );
    });

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        interval={false}
        
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} className=".hide-600-up" id="1" />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} id="2" />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} id="3" />
      </Carousel>
    );
  }
}


export default ProjectCarousel;
